.alert-message{
    margin-bottom: 40px;
    &__item{
        &:before{
            content: '';
            transform: none;
            border-radius: 100%;
            background-color: red;
        }
    }
}