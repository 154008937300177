.presentation {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 300px;
  @include mq($until: xxl) {
    margin-top: 400px;
  }
  @include mq($until: md) {
    grid-template-columns: 1fr;
    margin-top: 0;
  }
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__heading {
    padding-bottom: 50px;
    border-bottom: 4px solid $color-main;
    font-weight: $fw-semi-bold;
    display: inline-block;
    margin: 0;
  }
  &__text-wrapper {
    position: relative;
    padding: 50px 0 150px 0;
    &:before {
      content: "";
      position: absolute;
      width: 100vw;
      right: 0;
      top: -4px;
      height: 100%;
      transform: translateX(50%);
      z-index: -1;
      -webkit-box-shadow: 0px 0px 18px -9px rgba(0, 0, 0, 0.68);
      -moz-box-shadow: 0px 0px 18px -9px rgba(0, 0, 0, 0.68);
      box-shadow: 0px 0px 18px -9px rgba(0, 0, 0, 0.68);
      @include mq($until: md) {
        right: 50%;
      }
    }

    @include mq($until: xs) {
      padding: 50px 0 28vw 0;
    }
  }
  &__text {
    margin-bottom: 30px;
    line-height: 2em;
    padding-right: 100px;
    @include mq($until: xs) {
      padding-right: 0;
    }
  }

  &__desktop-wrapper {
    position: relative;
    @include mq($until: md) {
    }
  }

  &__frame {
    position: absolute;
    bottom: 30%;
    max-width: 100%;
    // left: 100px;
    right: 0px;
    // width: 816px;
    // height: 456px;
    @include mq($until: xxl) {
      //   width: 980px;
      //   height: 538px;
      right: -7vw;
    }
    @include mq($until: md) {
      max-width: 90%;
      position: relative;
      //   width: 58vw;
      //   height: 34vw;
      right: auto;
      left: 50%;
      bottom: auto;
      top: 35%;
      transform: translate(-50%, -50%);
    }
  }
  &__frame-bottom {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 150px);
    max-width: none;
    @include mq($until: sm) {
      //   top: calc(100% + 25px);
      width: calc(100% + 100px);
    }
    @include mq($until: xs) {
      width: calc(120%);
    }
  }
  &__video {
    position: relative;
    max-width: 100%;
    top: 0;
    left: 0;
    border-radius: 15px 15px 0 0;
    border-width: 30px 15px 30px 15px;
    border-style: solid;
    border-color: #000;
    display: block;
    @include mq($until: xxl) {
      //   width: 950px;
    }
    @include mq($until: md) {
      //   width: 100%;
      position: relative;
    }
    @include mq($until: sm) {
      border-width: 25px 10px 25px 10px;
    }
    @include mq($until: xs) {
      border-width: 15px 10px 15px 10px;
    }
  }
}
