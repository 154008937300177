.form-footer {
  padding-top: 180px;
  background-image: url("../../images/footer-bg.svg");
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
  position: relative;
  background-color: #fff;
  .footer-menu {
    @include mq($until: md) {
      order: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      &__item {
        margin: 10px;
      }
    }
  }
  &__phone-wrapper {
    white-space: nowrap;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
  }
  &__contact-phone {
    white-space: nowrap;
    font-size: rem(25px);
    margin-left: 5px;
  }
  &__form-wrapper {
    @include mq($until: md) {
      order: 1;
    }
  }
  &__wrapper {
    display: grid;
    grid-gap: 60px;
    padding-bottom: 120px;
    @include mq($until: md) {
      padding-bottom: 60px;
    }
  }
  &__logo {
    max-width: 150px;
  }

  &__inner-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    grid-gap: 100px;
    align-items: flex-start;
    @include mq($until: lg) {
      grid-template-columns: 200px 600px;
      grid-gap: 0;
    }
    @include mq($until: md) {
      grid-template-columns: 1fr;
      grid-gap: 100px;
    }
  }
  &__heading {
    font-size: rem(50px);
  }
  &__form-heading {
    margin-bottom: 40px;
    font-size: rem(20px);
    line-height: 27px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  &__element {
    font-size: rem(20px);
    font-weight: $fw-bold;
    color: $color-main;
    margin: 0 5px;
  }
  &__form {
    display: grid;
    grid-template-columns: minmax(300px, 550px) 300px;
    grid-gap: 20px;
    @include mq($until: lg) {
      grid-template-columns: 1fr;
    }
  }
  &__fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 25px;
    @include mq($until: xs) {
      grid-template-columns: 1fr;
    }
  }
  &__field-wrapper {
    position: relative;
    display: grid;
    height: 51px;
    -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
    &--checkbox {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      grid-template-columns: 51px 1fr;
      grid-gap: 15px;
      justify-content: flex-start;
      align-items: flex-start;
      height: auto;
      &:before {
        display: none;
      }
    }
    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      background-color: #fff;
    }
  }
  &__field-label {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    transition: 0.2s;
    padding: 5px 15px;
    font-weight: $fw-semi-bold;
    line-height: 26px;
    order: 1;
    z-index: 1;
    &.input-has-content {
      transform: translateY(-125%);
      color: $color-extra;
    }
    &--select {
      transform: translateY(-125%);
    }
  }
  &__field {
    position: relative;
    padding: 14px 15px 12px 15px;
    height: 100%;
    outline: none;
    border: none;
    order: 2;
    z-index: 2;
    width: 100%;
    background-color: transparent;
    -webkit-appearance: none;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-color: transparent;
    }
    &:focus {
      outline: none;
      & + .form-footer__field-label {
        transform: translateY(-125%);
        color: $color-extra;
      }
    }
  }

  &__accept {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    @include mq($until: lg) {
      justify-content: stretch;
    }
  }
  &__info {
    font-weight: $fw-semi-bold;
    height: 51px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__label-link {
    font-weight: $fw-semi-bold;
    text-decoration: underline;
    transition: color 0.3s;
    &:hover {
      text-decoration: underline;
      color: $color-main;
    }
  }
  &__checkbox-label {
    font-size: rem(12px);
    line-height: rem(15px);
    @include mq($until: lg) {
      font-size: rem(16px);
      line-height: 17px;
    }
  }
  &__checkbox-field {
    height: 51px;
    width: 100%;
    position: relative;
    -webkit-appearance: none;
    outline: none;
    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 51px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      z-index: 1;
    }
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "!";
      color: $color-main;
      font-weight: $fw-semi-bold;
      z-index: 2;
      font-size: rem(35px);
      //   opacity: 1;
      transition: opacity 0.2s;
    }
    &:checked:before {
      content: "✔";
    }
  }
  &__submit {
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: $color-main;
    transition: 0.3s;
    font-weight: 600;
    align-self: flex-end;
    cursor: pointer;
    &:hover {
      background-color: $color-extra;
      color: #fff;
    }
  }
}
