@keyframes scrollIpad {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  8% {
    transform: translateX(-50%) translateY(0);
  }
  25% {
    transform: translateX(-50%) translateY(-20%);
  }
  27% {
    transform: translateX(-50%) translateY(-20%);
  }
  45% {
    transform: translateX(-50%) translateY(-40%);
  }
  47% {
    transform: translateX(-50%) translateY(-40%);
  }
  70% {
    transform: translateX(-50%) translateY(-60%);
  }
  74% {
    transform: translateX(-50%) translateY(-60%);
  }
  98% {
    transform: translateX(-50%) translateY(-71%);
  }
  100% {
    transform: translateX(-50%) translateY(-71%);
  }
}

@keyframes scrollIphone {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  8% {
    transform: translateX(-50%) translateY(0);
  }
  25% {
    transform: translateX(-50%) translateY(-20%);
  }
  27% {
    transform: translateX(-50%) translateY(-20%);
  }
  45% {
    transform: translateX(-50%) translateY(-40%);
  }
  47% {
    transform: translateX(-50%) translateY(-40%);
  }
  70% {
    transform: translateX(-50%) translateY(-60%);
  }
  74% {
    transform: translateX(-50%) translateY(-60%);
  }
  98% {
    transform: translateX(-50%) translateY(-80%);
  }
  100% {
    transform: translateX(-50%) translateY(-80%);
  }
}

.top-baner {
  height: 100vh;
  width: 100%;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(2, 1fr);
  @include mq($until: md) {
    grid-template-columns: 65% 35%;
  }
  @include mq($until: xs) {
    grid-template-columns: 1fr;
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 65vw;
    height: 100vh;
    background-image: url("../../images/top-bg.svg");
    background-position: top left;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
    @include mq($until: lg) {
      width: 100%;
    }
  }
  &__content-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 120px;
    @include mq($until: xs) {
      padding-top: 80px;
    }
  }
  &__content-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding-right: 100px;
    @include mq($until: sm) {
      padding-right: 50px;
    }
    @include mq($until: xs) {
      padding-right: 0;
      z-index: 2;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba($color: #fff, $alpha: 0.9);
        height: calc(100% + 40px);
        width: 100vw;
        z-index: -1;
      }
    }
  }
  &__heading {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 4px solid $color-main;
    font-weight: $fw-semi-bold;
    @include mq($until: xs) {
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }
  &__text {
    margin-bottom: 30px;
    line-height: 2em;
    @include mq($until: xs) {
      margin-bottom: 0;
    }
  }
  &__presentation-wrapper {
    padding-top: 120px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($until: xs) {
      // position: absolute;
      // width: 75%;
      // right: 0;
      // padding-top: 80px;
      display: none;
    }
  }
  &__inner-wrapper {
    position: relative;
    height: calc(100% - 120px);
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include mq($until: xs) {
      max-width: 700px;
    }
  }

  &__ipad-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // max-height: 100%;
    width: 100%;
    @include mq($until: md) {
      width: 110%;
    }
  }
  &__iphone-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(60%, -45%);
    // max-height: 100%;
    width: 41%;
    @include mq($until: lg) {
      width: 36%;
    }
    @include mq($until: md) {
      width: 45%;
    }
    @include mq($until: sm) {
      width: 46%;
      transform: translate(60%, -7%);
    }
  }

  &__screen-wrapper {
    position: absolute;
    height: calc(100% - 5%);
    width: 100%;
    border-radius: 16%;
    top: 50%;
    left: 0;
    overflow: hidden;
    transform: translateY(-50%);
    z-index: -1;
  }

  &__screen-ipad {
    position: absolute;
    max-width: none;
    width: 100%;
    max-height: none;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(0);
    padding: 11% 5% 5% 5%;
    animation: scrollIpad 10s 2s infinite alternate ease;
  }

  &__screen-iphone {
    position: absolute;
    max-width: none;
    width: 100%;
    max-height: none;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(0);
    padding: 27% 6% 6% 6%;
    animation: scrollIphone 10s 2s infinite alternate ease;
  }

  &__frame {
    position: relative;
    display: block;
  }

  .shape1 {
    position: absolute;
    top: 100%;
    left: 100%;
    width: 600px;
    transform: translate(-40%, -70%);
    z-index: -1;
    @include mq($until: lg) {
      transform: translate(-72%, -80%);
    }
    @include mq($until: sm) {
      width: 500px;
      transform: translate(-40%, -70%);
    }
  }
}
