.offer {
  margin-top: 120px;
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  &--subsite {
    margin-top: 0;
    margin-bottom: 0;
  }
  &__heading {
    background-color: $color-main;
    margin-bottom: 70px;
    padding: 5px 50px 20px 5px;
    width: 80%;
    font-weight: $fw-semi-bold;
    font-size: rem($header-size / 1.3);
    @include mq($until: sm) {
      width: 90%;
    }
    @include mq($until: sm) {
      padding: 5px 35px 20px 5px;
    }
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 60px;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 70px;
    @include mq($until: xl) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq($until: md) {
      grid-template-columns: 1fr;
    }
    &--col6 {
      grid-template-columns: repeat(6, 150px);
      @include mq($until: xl) {
        grid-template-columns: repeat(4, 150px);
      }
      @include mq($until: md) {
        grid-column-gap: 20px;
        grid-row-gap: 30px;
        grid-template-columns: repeat(3, 150px);
      }
      @include mq($until: xs) {
        justify-content: center;
        grid-template-columns: repeat(2, 150px);
      }
      @media (max-width: 400px) {
        grid-template-columns: 150px;
      }
    }
  }
  &__item {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-gap: 30px;
    justify-content: flex-start;
    align-items: flex-start;
    // justify-items: center;
    // align-content: flex-start;
    &:hover {
      .offer__check {
        opacity: 1;
      }
      .shape {
        fill: $color-extra;
      }
    }
    &--col {
      grid-template-columns: 150px;
      &:hover {
        .offer__title {
          opacity: 1;
        }
      }
    }
  }
  &__icon {
    height: 150px;
    // width: 150px;
    flex-basis: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    background-color: #fff;
    svg {
      width: 80px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__title {
    font-weight: $fw-semi-bold;
    margin-bottom: 20px;
    font-size: rem($paragraph-size);
    &--center {
      transition: opacity 0.2s;
      opacity: 0;
      text-align: center;
    }
  }
  &__description {
    text-align: left;
  }
  &__check {
    position: absolute;
    opacity: 0;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    transition: opacity 0.3s;
  }
  &__text-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    @include mq($until: md) {
      flex-direction: column;
    }
    @include mq($until: sm) {
      // width: 90%;
    }
    @include mq($until: xs) {
      // width: 100%;
    }
  }

  &__img {
    width: 65%;
    object-fit: contain;

    @include mq($until: xl) {
      width: 55%;
    }

    @include mq($until: lg) {
      width: 49%;
    }

    @include mq($until: md) {
      min-width: 300px;
    }
  }

  &__text {
    width: 50%;
    margin-left: auto;
    max-width: 450px;
    @include mq($until: md) {
      margin-top: 20px;
      width: 100%;
      max-width: unset;
    }
  }

  .shape2 {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    @include mq($until: lg) {
      transform: translate(-72%, -80%);
    }
    @include mq($until: sm) {
      transform: translate(-40%, -70%);
    }
  }
}
