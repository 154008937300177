// =========================
// Fonts
// =========================

 @font-face {
     font-family: 'Montserrat';
     font-weight: 400;
     src: url('../../fonts/Montserrat-Regular.ttf');
 }
@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: url('../../fonts/Montserrat-SemiBold.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src: url('../../fonts/Montserrat-Bold.ttf');
}
