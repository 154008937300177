.subsite {
  padding-top: 200px;
  padding-bottom: 140px;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  width: 100%;
  @include mq($until: md) {
    padding-top: 140px;
    padding-bottom: 100px;
  }
  @include mq($until: xs) {
    padding-bottom: 70px;
  }
  &__page-title {
    font-size: rem(30px);
    font-weight: $fw-semi-bold;
    position: relative;
    display: inline-block;
    margin-bottom: 70px;
    margin-right: auto;
    padding-bottom: 15px;
    @include mq($until: xs) {
      margin-bottom: 50px;
    }

    &:after {
      content: "";
      width: 40%;
      height: 4px;
      background-color: $color-main;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.project {
  padding-bottom: 0;
  overflow: hidden;
}
