.laptop-section {
  -webkit-box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, 0.24);
  position: relative;
  @include mq($until: xs) {
    padding-left: 0;
    padding-right: 0;
  }

  &__heading {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @include mq($until: md) {
      flex-direction: column;
    }
  }

  &__inner-wrapper {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    grid-gap: 40px;
    padding-top: 55px;
  }
  &__title {
    text-align: center;
    margin-bottom: 0;
    color: $color-font-light;
    position: relative;
  }
  &__description {
    color: $color-font-light;
    text-align: center;
    position: relative;
    line-height: rem(30px);
    margin-bottom: 0;
    display: block;
  }
  &__presentation-wrapper {
    display: grid;
    grid-template-areas: ". mockup link";
    grid-template-columns: 1fr 70% 1fr;
    justify-content: center;
    align-items: center;
    position: relative;

    @include mq($until: md) {
      grid-template-areas:
        "link link link"
        ". mockup .";
    }

    @include mq($until: xs) {
      grid-template-columns: 1fr 80% 1fr;
    }
  }
  &__presentation-frame {
    grid-area: mockup;
    border-radius: 15px 15px 0 0;
    border-width: 30px 15px 30px 15px;
    border-style: solid;
    border-color: #000;
    position: relative;
    top: 20px;
    z-index: 1;
    @include mq($until: lg) {
      border-width: 18px 10px 18px 10px;
    }
    @include mq($until: xs) {
      border-width: 2.3vw 1.5vw 2.3vw 1.5vw;
    }
  }
  &__presentation-image {
    max-width: none;
    width: 100%;
    display: block;
  }
  &__frame-bottom {
    position: absolute;
    top: calc(100% + 28px);
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 12vw);
    max-width: none;
    @include mq($until: lg) {
      top: calc(100% + 18px);
    }
    @include mq($until: xs) {
      top: calc(100% + 2.3vw);
    }
  }
  &__link-wrapper {
    grid-area: link;
    text-align: center;
    @include mq($until: md) {
      margin: 15px 0;
    }
  }
  &__page-link {
    color: $color-font-light;
    height: 100%;
    width: 100%;
    padding: 20px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: $color-font-light;
    }
  }
}

.main-secondary {
  padding-top: 60px;
  -webkit-box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, 0.24);
  @include mq($until: xs) {
    padding-left: 0;
    padding-right: 0;
  }
  &__heading {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    @include mq($until: xl) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @include mq($until: md) {
      width: 100%;
    }
    @include mq($until: xs) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &__logo {
    margin-right: 30px;
    @include mq($until: xl) {
      margin-left: 0;
      margin-bottom: 30px;
    }
    @include mq($until: lg) {
      max-width: 150px;
    }
  }
  &__description {
    text-align: left;
    margin-bottom: 0;
    @include mq($until: xl) {
      text-align: center;
    }
  }
  &__inner-wrapper {
    margin-top: 30px;
    padding-top: 20vw;
    position: relative;
    display: grid;
    grid-template-areas:
      ". . link"
      ". image .";
    grid-template-columns: 1fr 70% 1fr;
    align-items: flex-end;
    align-content: flex-end;
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
    width: 100%;
    @include mq($until: xxl) {
      grid-template-areas:
        ". link ."
        ". image .";
    }
    @include mq($until: xs) {
      grid-template-columns: 1fr 80% 1fr;
    }
  }
  &__bg-extra {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
    max-width: 50%;
  }
  &__image {
    width: 100;
    grid-area: image;
    position: relative;
    z-index: 1;
  }
  &__link-wrapper {
    grid-area: link;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  &__page-link {
    color: $color-font-light;
    height: 100%;
    width: 100%;
    padding: 30px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: $color-font-light;
    }
    @include mq($until: lg) {
      padding: 15px 10px;
    }
  }
}

.text-section {
  position: relative;
  @include mq($until: xs) {
    padding-left: 0;
    padding-right: 0;
  }

  &__page-image-wrapper {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      background-color: #343434;
      z-index: -1;
      top: 0;
      right: 100%;
      height: 100%;
      width: 100vw;
    }
    &:after {
      content: "";
      position: absolute;
      background-color: #343434;
      z-index: -1;
      top: 0;
      left: 100%;
      height: 100%;
      width: 100vw;
    }
  }
  &__page-image {
    display: block;
    width: 70%;
    margin: 0 auto;
    @include mq($until: xs) {
      width: 80%;
    }
  }
  &__media-box-wrapper {
    position: absolute;
    bottom: calc(100% - 40px);
    left: 0;
    max-width: 40%;
    @include mq($until: md) {
      max-width: 58%;
    }
    @include mq($until: xs) {
      max-width: 90%;
    }
  }
  &__text-box-wrapper {
    position: relative;
    top: calc(100% - 30px);
    right: 0;
    @include mq($until: xs) {
      width: 100%;
      top: calc(100% - 10px);
    }
  }
  &__content-wrapper {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    padding: 0;
    left: 50%;
    width: 100%;
    z-index: 1;
    transform: translateX(-50%);
    @include mq($until: xs) {
      position: relative;
      width: 100vw;
      flex-direction: column;
    }
  }
  &__media-box {
    position: relative;
    border-width: 8px 8px 8px 0;
    border-style: solid;
    border-color: #fff;
    border-radius: 0 5px 5px 0;
    -webkit-box-shadow: 1px 1px 9px 4px rgba(0, 0, 0, 0.34);
    -moz-box-shadow: 1px 1px 9px 4px rgba(0, 0, 0, 0.34);
    box-shadow: 1px 1px 9px 4px rgba(0, 0, 0, 0.34);
  }
  &__media-box-title-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 5px;
    width: 90%;
    @include mq($until: md) {
      padding: 15px 10px;
    }
    // @include mq($until: xs){
    //     padding: 10px 10px;
    // }
  }
  &__media-box-title-bg {
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  &__media-box-title {
    margin: 0;
    position: relative;
    z-index: 2;
    color: $color-font-light;
    font-weight: $fw-semi-bold;
    @include mq($until: xs) {
      font-size: rem(20px);
    }
  }
  &__media-box-image {
    display: block;
    max-width: 100%;
    border-radius: 0 5px 5px 0;
    @include mq($until: md) {
      max-height: 250px;
    }
    @include mq($until: xs) {
      position: relative;
    }
  }
  &__text-box {
    position: relative;
    bottom: 0;
    border-radius: 10px 0 0 10px;
    margin-left: auto;
    width: 90%;
    min-height: 180px;
    padding: 50px 40px;
    -webkit-box-shadow: 1px 1px 9px 4px rgba(0, 0, 0, 0.34);
    -moz-box-shadow: 1px 1px 9px 4px rgba(0, 0, 0, 0.34);
    box-shadow: 1px 1px 9px 4px rgba(0, 0, 0, 0.34);
    @include mq($until: md) {
      padding: 20px 20px;
      min-height: 150px;
    }
    @include mq($until: xs) {
      width: 100%;
      border-radius: 0;
      padding: 15px 20px;
    }
  }
  &__text-box-title {
    color: $color-font-light;
    font-weight: $fw-medium;
    position: relative;
    z-index: 1;
  }
  &__text-box-content {
    position: relative;
    z-index: 1;
    color: $color-font-light;
    max-width: 60%;
    @include mq($until: md) {
      max-width: 90%;
    }
    @include mq($until: xs) {
      max-width: 100%;
    }
  }

  &__text-box-logo {
    position: absolute;
    z-index: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 70%;
    opacity: 0.3;
  }
}

.presentation-section {
  position: relative;
  @include mq($until: xs) {
    padding-left: 0;
    padding-right: 0;
  }

  &__content-wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      background-color: #343434;
      z-index: -1;
      top: 0;
      right: 100%;
      height: 100%;
      width: 100vw;
    }
    &:after {
      content: "";
      position: absolute;
      background-color: #343434;
      z-index: -1;
      top: 0;
      left: 100%;
      height: 100%;
      width: 100vw;
    }
  }
  &__image-wrapper {
    width: 70%;
    margin: 0 auto;
    @include mq($until: xs) {
      width: 80%;
      position: relative;
    }
  }
  &__image {
    width: 100%;
    display: block;
  }
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    padding: 25px 90px;
    border-radius: 10px;
    font-size: rem(20px);
    color: $color-font-light;
    transition: transform 0.2s;
    line-height: rem(25px);
    text-align: center;
    white-space: nowrap;
    @include mq($until: lg) {
      padding: 20px 40px;
    }
    @include mq($until: xs) {
      font-size: rem(16px);
      padding: 15px 20px;
    }
    &:hover {
      transform: translateX(-50%) scale(1.05);
      color: $color-font-light;
    }
  }
}

.images-section {
  position: relative;
  @include mq($until: xs) {
    padding-left: 0;
    padding-right: 0;
  }
  &__wrapper {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      background-color: #343434;
      z-index: -1;
      top: 0;
      right: 100%;
      height: 100%;
      width: 100vw;
    }
    &:after {
      content: "";
      position: absolute;
      background-color: #343434;
      z-index: -1;
      top: 0;
      left: 100%;
      height: 100%;
      width: 100vw;
    }
  }

  &__inner-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0 auto;
    background-color: transparent;
    @include mq($until: md) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    @include mq($until: xs) {
      width: 80%;
    }
  }
  //   &__text-box {
  //     margin: 0 auto;
  //     margin-bottom: 40px;
  //   }
  &__header {
    width: 60%;
    font-weight: $fw-medium;
    color: $color-font-light;
    display: block;
    margin: 40px 0;
    font-size: rem(30px);
    @include mq($until: lg) {
      width: 70%;
    }
    @include mq($until: md) {
      width: 80%;
      margin-bottom: 20px;
    }
    @include mq($until: sm) {
      width: 90%;
    }
    @include mq($until: xs) {
      width: 100%;
    }
  }
  &__description {
    width: 60%;
    color: $color-font-light;
    line-height: rem(30px);
    margin-bottom: 40px;
    display: block;
    @include mq($until: lg) {
      width: 70%;
    }
    @include mq($until: md) {
      width: 80%;
      margin-bottom: 20px;
    }
    @include mq($until: sm) {
      width: 90%;
    }
    @include mq($until: xs) {
      width: 100%;
    }
  }
  &__image {
    order: 3;
    margin: 0 auto;
    padding: 100px 0;
    display: block;
    @include mq($until: md) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
}

.logo-section {
  position: relative;
  @include mq($until: xs) {
    padding-left: 0;
    padding-right: 0;
  }

  &__logo {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
    width: 40%;
    @include mq($until: xs) {
      width: 60%;
    }
  }

  &__bg-image {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    position: relative;
    width: 100%;
  }
  &:after {
    content: "";
    position: absolute;
    background-color: #343434;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
  }
  &:before {
    content: "";
    position: absolute;
    background-color: #343434;
    z-index: -1;
    top: 0;
    right: 0;
    height: 100%;
    width: 100vw;
  }
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    padding: 25px 90px;
    border-radius: 10px;
    font-size: rem(20px);
    color: $color-font-light;
    transition: transform 0.2s;
    line-height: rem(25px);
    text-align: center;
    white-space: nowrap;
    @include mq($until: lg) {
      padding: 20px 40px;
    }
    @include mq($until: xs) {
      font-size: rem(16px);
      padding: 15px 20px;
    }
    &:hover {
      transform: translateX(-50%) scale(1.05);
      color: $color-font-light;
    }
  }
}

.image-text-section {
  position: relative;
  @include mq($until: xs) {
    padding-left: 0;
    padding-right: 0;
  }

  &__wrapper {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      background-color: #343434;
      z-index: -1;
      top: 0;
      right: 100%;
      height: 100%;
      width: 100vw;
    }
    &:after {
      content: "";
      position: absolute;
      background-color: #343434;
      z-index: -1;
      top: 0;
      left: 100%;
      height: 100%;
      width: 100vw;
    }
  }

  &__inner-wrapper {
    position: relative;
    width: 70%;
    margin: 0 auto;
    @include mq($until: xs) {
      width: 100%;
    }
    &:after {
      position: absolute;
      top: 100%;
      left: 0;
      transform: translateY(-101%);
      content: "";
      height: 80px;
      width: 100%;
      background-color: #fff;
      border-radius: 0 0 15px 15px;
      @include mq($until: xs) {
        border-radius: 0;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.8463760504201681) 32%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }

  &__box-wrapper {
    position: relative;
    top: -80px;
    overflow: hidden;
    border-radius: 15px 15px 0 0;
    // background-color: transparent;
    padding-top: 50px;
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 80%,
      rgba(255, 255, 255, 0.8463760504201681) 100%
    );
    @include mq($until: xs) {
      border-radius: 0;
    }
  }
  &__header {
    z-index: 1;
    position: relative;
    font-weight: $fw-medium;
    text-align: center;
    margin: 40px 0;
  }
  &__description {
    z-index: 1;
    text-align: center;
    position: relative;
    line-height: rem(40px);
  }
  &__image-wrapper {
    position: relative;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 300px;
  }
  &__image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: calc(100% + 100px);
    max-width: none;
    @include mq($until: sm) {
      height: calc(100% + 50px);
    }
  }
}
