.footer-default{
    &__top-bar{
        padding-top: 40px;
        padding-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr auto auto auto;
        justify-content: flex-end;
        align-items: center;
        @include mq($until: lg){
            grid-template-columns: 1fr 1fr 2fr;
            grid-template-areas: 
            "logo logo button"
            "phone phone phone"
            "email email email";
        }
        @include mq($until: xs){
            grid-template-columns: 1fr;
            grid-template-areas: 
            "logo"
            "phone"
            "email"
            "button";
        }

    }
    &__logo{
        max-width: 150px;
        margin-bottom: 40px;
        @include mq($until: lg){
            grid-area: logo;   
        }
        

    }
    &__contact-wrapper{
        margin: 0 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        &--phone{
            margin-left: 0;
            @include mq($until: lg){
                grid-area: phone; 
                margin-right: auto; 
            }
        }
        &--email{
            margin-right: 0;
            @include mq($until: lg){
                grid-area: email; 
                margin-right: auto; 
                margin-left: 0;
            }
        }
        &:hover{
            .footer-default__contact-label{
                color: $color-extra;
            }
            svg{
                fill: $color-extra;
            }
        }
    }
    &__contact-label{
        margin-left: 15px;
        font-weight: $fw-bold;
        font-size: rem(18px);
        transition: color .2s;
        white-space: nowrap;
    }
    &__link{
        margin-left: 40px;
        padding: 10px 50px;
        font-weight: $fw-bold;
        margin-bottom: 40px;
        white-space: nowrap;
        @include mq($until: lg){
            grid-area: button;
            margin-left: auto;  
        }
        @include mq($until: xs){
            margin-left: 0;
            margin-right: auto; 
        }
    }

}