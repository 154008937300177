.home-slider{
    padding-top: 180px;
    background-image: url('../../images/slider-bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @include mq($until: xs){
        padding-top: 100px;
    }
    &__wrapper{
        width: 100%;
        position: relative;
        @media (max-width: 1880px) {
            padding-right: 0;
            padding-left: 0;
        }
        &.width-wrapper{
            @media (max-width: 1920px) {
                margin-right: 0; 
                margin-left:  0; 
                max-width: none; 
                padding-right: 0;  
                padding-left: 0;
            }            
        }

    }
    &__container{
        display: flex;
        align-items: stretch;
        width: 100%;
        @include mq($until: xs){
            position: relative;
            z-index: 1;
        }
    }
    &__slider-arrows{
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq($until: xs){
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translateY(50%);
            width: auto;
            height: auto;
            padding: 10px;
            background-color: rgba($color: #fff, $alpha: .8);
            -webkit-box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.68);
            -moz-box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.68);
            box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.68);
        }
        .slider-arrow--prev{
            margin-right: 10px;
        }
        .slider-arrow--next{
            margin-left: 10px;
        }
    }
    &__image-slider{
        height: 500px;
        width: 75%;
        @include mq($until: lg){
            height: 400px;
        }
        @include mq($until: md){
            height: 350px;
        }
        @include mq($until: xs){
            width: 100%;
            height: 250px;
        }
    }
    &__image-item{
        height: 100%;
        display: grid;
        grid-template-columns: 70% 30%;
        &:after{
            content: "";
            display: block;
            height: 100%;
        }
        
    }
    &__image{
        background-repeat: no-repeat;
        height: 500px;
        background-size: cover;
        background-position: center;
        @include mq($until: lg){
            height: 400px;
        }
        @include mq($until: md){
            height: 350px;
        }
        @include mq($until: xs){
            height: 250px;
        }

    }
    &__text-slider{
        width: 60%;
        position: relative;
        bottom: 100px;
        margin-left: auto;
        /* the slides */
        .slick-slide {
            margin: 20px;
        }
        /* the parent */
        .slick-list {
            margin: 0 -20px;
        }
        @include mq($until: sm){
            width: 85%;
        }
        @include mq($until: xs){
            bottom: 20px;
            width: 100%;
        }
    }
    &__text-item{
        padding-top: 30px;
        padding-left: 30px;
        padding-bottom: 30px;
        padding-right: $padding-xxl;
        background: linear-gradient(308deg, rgba(255,255,255,1) 38%, rgba(255,255,255,0.9248074229691877) 100%);
        -webkit-box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.68);
        -moz-box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.68);
        box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.68);
        min-height: 350px;
        @include mq($until: xl){
          padding-right: $padding-xl;
        }
        @include mq($until: lg) {
          padding-right: $padding-lg;
          min-height: 300px;
        }
        @include mq($until: sm) {
          padding-right: $padding-sm;
        }
        @include mq($until: xs) {
          padding-top: 50px;
        }
    }

    &__heading{
        width: 50%;
        line-height: 1.5em;
        margin-bottom: 30px;
        @include mq($until: xs){
            width: 100%; 
        }
    }
    &__text{
        line-height: 2em;  
        p{
            line-height: 2em;  
        }          
    }
}