.footer-menu{
    margin: 0;
    padding: 0;
    display: grid;
    grid-gap: 20px;
    &--horizontal{
        display: flex;
        flex-wrap: wrap;
        padding-top: 35px;
        padding-bottom: 35px;
        grid-gap: 0;
        @include mq($until: md){
            justify-content: center;
            align-items: center;            
        }
        .footer-menu__item{
            margin: 0 30px;
            &:first-child{
                margin-left: 0;
            }
            &:last-child{
                margin-right: 0;
            }
            @include mq($until: md){
                margin: 20px 30px;
            }
        }
        .footer-menu__link{
            font-size: rem(18px);
            &:hover{
                color: $color-extra
            }
        }
    }
    &__item{
        margin: 0;
        padding: 0;
        &:before{
            display: none;
        }
    }
    &__link{
        font-weight: $fw-bold;
        transition: color .3s;
        &:hover{
            color: $color-main
        }
    }
}