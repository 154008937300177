.button {
  padding: 10px 50px 20px 10px;
  border: none;
  background-color: $color-main;
  transition: .3s;
  font-weight: $fw-semi-bold;
  display: inline-block;
  cursor: pointer;
  &:hover{
      background-color: $color-extra;
      color: $color-font-light;
  }
}