.projects{
    -webkit-box-shadow:  0px 0px 18px -9px rgba(0,0,0,0.68);
    -moz-box-shadow:  0px 0px 18px -9px rgba(0,0,0,0.68);
    box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.68);
    padding-bottom: 140px;
    position: relative;
    &:after{
        content: "";
        width: 100%;
        height: 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: transparent;
        box-shadow: inset 0px 11px 13px -13px rgba(0, 0, 0, 0.58);
        -webkit-box-shadow: inset 0px 11px 13px -13px rgba(0, 0, 0, 0.58);
        -moz-box-shadow: inset 0px 11px 13px -13px rgba(0, 0, 0, 0.58);
    }
    &__heading-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.width-wrapper{
            @media (max-width: 1920px) {
                margin-right: 0; 
                margin-left:  0; 
                max-width: none; 
                padding-right: 0;  
                padding-left: 0;
            }
        }
    }
    &__heading{
        margin-bottom: 0;
    }
    &__heading-button{
        padding: 50px 100px 50px 40px;
        @include mq($until: sm){
            padding: 50px 50px 50px 40px;
        }
        @include mq($until: xs){
            padding: 30px 30px 30px 20px;
        }
    }
    &__grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 15px;
        grid-column-gap: 15px;
        width: 100%;
        &.width-wrapper{ 
            @media (max-width: 1920px) {
                margin-right: 0; 
                margin-left:  0; 
                max-width: none; 
                padding-right: 0; 
                padding-left:  0; 
            }

        }
        @include mq($until: lg){
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($until: sm){
            grid-template-columns: 1fr;
        }
    }
    &__item{
        position: relative;
        height: 500px;
        overflow: hidden;
        &--small{
            height: 350px;
        }
        &:hover{
            .projects__image{
                transform: translateY(-20%);
            }
            &:after{
                background-color: rgba($color: $color-extra, $alpha: .8);
            }
            .projects__item-button{
                opacity: 1;
            }
        }
        &:after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transition: background-color 1s ease-in-out;
            background-color: transparent;
            z-index: 2;
        }
    }
    &__image{
        position: absolute;
        width: 100%;
        transition: transform 1s ease-in-out;
        z-index: 1;
    }
    &__item-button{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: 1s ease-in-out;
        z-index: 3;
        &:hover{
            color: $color-font-dark;
            background-color: $color-main;
        }
    }
}