.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100px;
  z-index: 10;

  &--fixed {
    position: fixed;
    transition: background-color 0.3s ease-in-out;
    &.sticky {
      background-color: lemonchiffon;
    }
  }
  &.home-header {
    .header__logo img {
      &:before {
        display: none;
      }
    }
  }
  &__logo {
    max-width: 150px;

    // img{
    //   position: relative;
    //   &:before{
    //     content: "";
    //     position: absolute;
    //     bottom: 0;
    //     left: 50%;
    //     height: 150%;
    //     width: 200%;
    //     transform: translateX(-50%) rotate(-90deg);
    //     background-image: url('../../images/shape1.svg');
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     background-position: bottom center;
    // }
    // }
  }
  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__menuItem {
    margin: 0 40px;
    padding: 0;
    @include mq($until: lg) {
      margin: 0 15px;
    }

    &::before {
      display: none;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__menuLink {
    color: #3e3e3e;
    text-transform: lowercase;
    font-weight: $fw-bold;
    font-size: rem(20px);
    &:active,
    &--active,
    &--open,
    &:hover {
      color: $color-extra;
    }
    @include mq($until: md) {
      font-size: rem(30px);
    }
  }
  .shape2 {
    position: absolute;
    left: 0;
    top: 0;
    transform: scaleY(-1) scaleX(-1) translate(50%, 70%);
    z-index: -1;
  }
}
