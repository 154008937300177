.slider-arrow{
    padding: 4px 10px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .3s;
    cursor: pointer;
    overflow: hidden;
    i{
        color: $color-font-light;
        font-size: 42px;
        transition: transform .3s;

    }
    &:hover{
        background-color: $color-extra;
        i{
            transform: translateX(0);
        }
    }
    &--prev{
        i{
            transform: translateX(17px);
        }
    }
    &--next{
        i{
            transform: translateX(-17px);
        }
    }
}