.offer-list {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  &__wrapper {
    display: grid;
    width: 100%;
    @include mq($until: xl) {
      grid-gap: 40px;
    }
    @include mq($until: sm) {
      grid-gap: 60px;
    }
  }
  &__item {
    display: grid;
    grid-template-columns: 350px 1fr;
    grid-template-areas: "image text";
    grid-gap: 50px;
    justify-content: center;
    align-items: center;
    @include mq($until: sm) {
      grid-gap: 20px;
      grid-template-columns: 200px 1fr;
    }
    @include mq($until: xs) {
      grid-template-columns: 1fr;
      position: relative;
      min-height: 200px;
    }
    .offer-list__shape-image {
      grid-area: image;
      position: relative;
      @include mq($until: xs) {
        position: absolute;
        top: 50%;
        left: 0;
        z-index: -1;
        transform: translateY(-50%);
        height: 200px;
        width: 350px;
        opacity: 0.1;
        img {
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }

    &:nth-child(even) {
      grid-template-columns: 1fr 350px;
      grid-template-areas: "text image";
      @include mq($until: sm) {
        grid-template-columns: 1fr 200px;
      }
      @include mq($until: xs) {
        grid-template-columns: 1fr;
      }
      .offer-list__content {
        text-align: right;
      }
      .offer-list__shape-image {
        @include mq($until: xs) {
          left: auto;
          right: 0;
          img {
            left: auto;
            right: 0;
          }
        }
      }
    }
    &:hover .offer-list__icon .shape {
      fill: $color-extra;
    }
  }
  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    border-radius: 100%;
    height: 100px;
    width: 100px;
    padding: 20px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    @include mq($until: sm) {
      height: 70px;
      width: 70px;
      padding: 15px;
    }
    @include mq($until: xs) {
      display: none;
    }
    svg {
      max-width: 100%;
    }
  }
  &__content {
    grid-area: text;
  }
}
