.single-offer {
  position: relative;
  display: grid;
  grid-template-columns: 7fr 3fr;
  grid-gap: 80px;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 200px;
  @include mq($until: lg) {
    grid-gap: 40px;
  }
  @include mq($until: md) {
    grid-template-columns: 1fr;
  }
  @include mq($until: xs) {
    margin-bottom: 150px;
  }
  &__content-wrapper {
    display: grid;
    grid-gap: 70px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__text-wrapper {
    p {
      margin-bottom: 0;
    }
  }
  &__spec {
    width: 80%;
    @include mq($until: xl) {
      width: 90%;
    }
    @include mq($until: lg) {
      width: 100%;
    }
  }
  &__spec-title {
    // font-size: rem(30px);
    font-weight: $fw-semi-bold;
    margin-bottom: 70px;
  }
  &__spec-items {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
  &__spec-item {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-gap: 40px;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dadada;
    @include mq($until: sm) {
      grid-template-columns: 80px 1fr;
      grid-gap: 30px;
    }
    @include mq($until: xs) {
      grid-template-columns: 1fr;
    }
    &:before {
      display: none;
    }
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }
  }
  &__icon {
    svg {
      width: 100%;
      fill: $color-extra;
    }
    @include mq($until: xs) {
      width: 80px;
      margin: 0 auto;
    }
  }
  &__description {
    @include mq($until: xs) {
      text-align: center;
    }
  }
  &__shape-image {
    position: relative;
    @include mq($until: md) {
      position: absolute;
      z-index: -1;
      opacity: 0.08;
      max-width: 50%;
      top: 0;
      right: 0;
    }
    @include mq($until: sm) {
      max-width: 70%;
    }
    @include mq($until: xs) {
      max-width: 80%;
    }
    img {
      position: sticky;
      top: 0;
      left: 0;
    }
  }

  .shape1 {
    position: absolute;
    left: 40%;
    width: 800px;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}
