.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 35px;
    padding-bottom: 35px;
    border-top: 1px solid #D1D1D1;
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }
}